export default {
  servicesIntro:
    "At Hope Labs, we believe that superior communications can help your organisation grow sustainably. Whether you want to engage more deeply with your audiences or maintain high levels of employee satisfaction, we can assist you at every step of the journey. Our skill set is versatile and highly technical: we are exactly what you need to help understand and navigate the complexity of the modern communication landscape. ",
  S1: {
    title: "Market Research",
    description:
      "Hope Labs conducts in-depth research and media analysis to help you understand your competition, trends and opportunities.",
  },
  S2: {
    title: "Brand Strategy",
    description:
      "We help you incorporate your company's mission, vision and values in your communication strategy.",
  },
  S3: {
    title: "Content Development",
    description:
      "From strategy to execution, we can help you reach your community and engage with it meaningfully.",
  },
  S4: {
    title: "Digital Marketing",
    description:
      "From our analysis of your audiences, we can help you devise successful and cost effective campaigns.",
  },
  S5: {
    title: "Data-Driven Insights",
    description:
      "We collect data, create key metrics and provide you with actionable insights in a clear format.",
  },
  S6: {
    title: "Training",
    description:
      "We organise and deliver engaging courses in Marketing and Advertising that are tailored to your organisation's needs.",
  },
};
