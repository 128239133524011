import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Info from "../components/info/info";

import { BiSearchAlt } from "@react-icons/all-files/bi/BiSearchAlt";
import { BiBrain } from "@react-icons/all-files/bi/BiBrain";
import { BiMessageDetail } from "@react-icons/all-files/bi/BiMessageDetail";
import { BiWind } from "@react-icons/all-files/bi/BiWind";
import { BiNetworkChart } from "@react-icons/all-files/bi/BiNetworkChart";
import { BiTrendingUp } from "@react-icons/all-files/bi/BiTrendingUp";
import servicesDescription from "../documents/descriptions/servicesDescription";

const primaryColor = "#f44f18";

const ValuesPart = () => {
  return (
    <section role="main">
      <Row className={`presentation justify`}>
        <h1 className={"mt-2em"}>How can we help you?</h1>
        <p>{servicesDescription.servicesIntro}</p>
      </Row>
      <Row className={"justify mt-2em"}>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={BiSearchAlt}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={servicesDescription.S1.title}
            description={servicesDescription.S1.description}
          />
        </Col>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={BiBrain}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={servicesDescription.S2.title}
            description={servicesDescription.S2.description}
          />
        </Col>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={BiMessageDetail}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={servicesDescription.S3.title}
            description={servicesDescription.S3.description}
          />
        </Col>
      </Row>
      <Row className={"justify mt-2em mb-2em"}>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={BiWind}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={servicesDescription.S4.title}
            description={servicesDescription.S4.description}
          />
        </Col>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={BiNetworkChart}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={servicesDescription.S5.title}
            description={servicesDescription.S5.description}
          />
        </Col>
        <Col xs={12} md={4} lg={4} xl={4}>
          <Info
            icon={BiTrendingUp}
            iconColor={primaryColor}
            iconSize={"100px"}
            title={servicesDescription.S6.title}
            description={servicesDescription.S6.description}
          />
        </Col>
      </Row>
    </section>
  );
};

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "images/covers/Moraine-Lake-Canada.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Metadata
        title="Our Services"
        description="Hope Labs offers a range of services to help charities and social ventures grow."
      />
      <section role="main">
        <Row className={`imageBox no-margin`}>
          <div
            className={`imageFilter d-none d-sm-block`}
            style={{ opacity: 0.4 }}
          ></div>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Moraine Lake mirrors the mountains"
          />
          <h1 className={`overlayTitle d-none d-sm-block`}>
            {"Communicate to Grow"}
          </h1>
        </Row>
        <ValuesPart />
        <Row className={`presentation justify`}>
          <h1 style={{ marginTop: "1em" }}>Would you like to know more?</h1>
          <div role="button" className={"moreButton mb-2em"}>
            <Link to={`/contact/`}>Contact us</Link>
          </div>
        </Row>
      </section>
    </Layout>
  );
};

export default About;
