import React from "react";
import PropTypes from "prop-types";
import infoStyles from "./info.module.css";
import { IconContext } from "@react-icons/all-files";

const Info = props => {
  const { title, description, children } = props;

  const IconStyle = props => {
    const { iconColor, iconSize, iconClass } = props;
    return (
      <IconContext.Provider
        value={{ color: iconColor, size: iconSize, className: iconClass }}
      >
        {props.children}
      </IconContext.Provider>
    );
  };
  return (
    <div className={infoStyles.infoArea}>
      <div className={infoStyles.iconWrapper}>
        <IconStyle
          iconColor={props.iconColor}
          iconSize={props.iconSize}
          iconClass={props.iconClass}
        >
          <props.icon />
        </IconStyle>
      </div>
      <div className={infoStyles.descriptionWrapper}>
        {props.firstLetterColor.length > 0 ? (
          <h4 className={infoStyles.title}>
            <span
              style={{
                color: props.firstLetterColor,
                fontWeight: 600,
                fontSize: "120%",
              }}
            >
              {title[0]}
            </span>
            {title.substring(1)}
          </h4>
        ) : (
          <h4 className={infoStyles.title}>{title}</h4>
        )}
        {props.justifyText === true ? (
          <div style={{ minHeight: props.fixedHeight }}>
            <p
              className={`${infoStyles.description} ${infoStyles.justifyText}`}
            >
              {description}
            </p>
          </div>
        ) : (
          <div style={{ minHeight: props.fixedHeight }}>
            <p className={infoStyles.description}>{description}</p>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Info;

Info.defaultProps = {
  icon: "...",
  iconColor: "gray",
  iconSize: "50px",
  iconClass: "",
  title: "",
  firstLetterColor: "",
  description: "...",
  fixedHeight: "auto",
  justifyText: false,
};

Info.propTypes = {
  icon: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  firstLetterColor: PropTypes.string,
  description: PropTypes.string.isRequired,
  fixedHeight: PropTypes.string,
  justifyText: PropTypes.bool,
  children: PropTypes.node,
};
